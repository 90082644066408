$(document).ready(function() {

    /*******************************************/
    //Form Validation
    $('.validate-form').each(function() {
        $(this).validate({
            submitHandler: function(form) {
                form.submit();
            },
            rules: {
                required: true
            },
            errorPlacement: function(error, element) {
                error.appendTo(element.parents('.form-field'));
            },
            highlight: function(element, errorClass, validClass) {
                if (element.type === 'radio') {
                    this.findByName(element.name).parents('.form-field').addClass(errorClass).removeClass(validClass);
                } else {
                    $(element).parents('.form-field').addClass(errorClass).removeClass(validClass);
                }
            },
            unhighlight: function(element, errorClass, validClass) {
                if (element.type === 'radio') {
                    this.findByName(element.name).parents('.form-field').removeClass(errorClass).addClass(validClass);
                } else {
                    $(element).parents('.form-field').removeClass(errorClass).addClass(validClass);
                }
            }
        });
    });

    /*******************************************/
    //Custom Select Dropdown 
    $(function() {
        $('.custom-select-dropdown').selectric();
        $('.select2').select2();
    });

    $(window).resize(function() {
        $('.select2').width('100%');
    });

    /*******************************************/
    //Lightbox Initiations
    $('.open-popup-link').magnificPopup({
        type:'inline',
        showCloseBtn: false,
        closeOnBgClick: true,
        // removalDelay: 300,
        mainClass: 'mfp-zoom-in',
        midClick: true
    });

    $('.lightbox-manual-close-trigger').click(function(event) {
        event.preventDefault();
        $.magnificPopup.close();
    });

    /*******************************************/
    // Tabs
    $('.js-tab-triggers a').on('click', function(e) {
        e.preventDefault();
        $('.js-tab-triggers a').not(this).removeClass('active');
        $(this).addClass('active');
        var getTarget = $(this).attr('href');
        $('.tab-content').not(getTarget).hide();
        $(getTarget).show();
    });

    /*******************************************/

});


/*******************************************/
$.fn.toggleHTML = function(t1, t2) {
    if (this.html() == t1) this.html(t2);
    else this.html(t1);
    return this;
};


/*******************************************/
//Main Menu
function slideMenu(action){ 
    var $menu = $('#mobile-menu') 
    if (!action) { toggle(); return }
    if (action == "open") { open(); return }
    if (action == "close") { close(); return }

    function open(){
        $("body").addClass('open-menu');
        $menu.attr('status', 'open');
    }
    function close(){
        $("body").removeClass('open-menu');
        $menu.attr('status', 'closed');
    }
    function toggle(){
        var status =  $menu.attr('status');
        if (status == "open"){ close(); return }
        if (status == "closed"){ open(); return }
    }
}

$('#mobile-menu li').each(function(){
        $(this).has("ul").addClass('mobile-menu-parent');
});

$( ".mobile-menu-parent .icon" ).on("click", function(){
    $(this).siblings('ul').slideToggle(150);
});

//prevent slide up
$(".mobile-menu-parent").on("click", "ul", function(event){
    event.stopPropagation()
})

/*******************************************/
// Header Highlighter
$(document).ready(function() {
    var headerHref = "#site-header";
    var mobileHref = "#mobile-menu";
    var myURL = "/" + location.pathname.split('/')[1];

    $(headerHref + ' li').each(function(){
        if ($(this).find('a').attr('href') == myURL) {
            $(this).addClass("selected")
            $(this).find('a').addClass("selected")
            return
        }
    })

    $(mobileHref + ' li').each(function(){
        if ($(this).find('a').attr('href') == myURL) {
            $(this).addClass("selected")
            $(this).find('a:first').addClass("selected")

            // nested menu
            if (location.pathname.split('/')[2] != undefined) {
                var subURL = myURL +'/'+ location.pathname.split('/')[2];

                $(this).find('ul.dropdown-content a').each(function() {
                    if ($(this).attr('href') == subURL) {
                         $(this).addClass("selected")
                    }
                });
            }

            return
        }
    })
});

/*******************************************/
// Header Menu Hover
$(document).ready(function() {
    $('.main-menu .parents').hover(function() {
        $(this).parent().find('.dropdown-content').css('display', 'block');
        $(this).parent().find('.has-chevron').addClass('active');

        $(this).parent().find('.dropdown-content').hover(function() {
            $(this).css('display', 'block');
            $(this).find('.has-chevron').addClass('active');
        },
        function(){ 
            $(this).css('display', 'none');
            $(this).find('.has-chevron').removeClass('active');
        });
    });

    $('.main-menu li:not(.parents)').hover(function() {
        $(this).parent().find('.dropdown-content').css('display', 'none');
        $(this).parent().find('.has-chevron').removeClass('active');
    },
    function() {

    });

    $('body').on('click', function() {
        $(document).find('.dropdown-content').css('display', 'none');
        $(document).find('.has-chevron').removeClass('active');
    });
});

/*******************************************/
// Scroll down to specific page
$(".js-scroll-down").click(function() {
    $('html, body').animate({
        scrollTop: $(".js-scroll-down-target").offset().top - 60
    }, 1500);
});